import React from 'react';

import VerifyEmail from 'components/pages/sign-up/email';
import MainLayout from 'layouts/main';

const verifyEmailDetails = {
  title: 'Verify email',
  details: {
    title: `Let's verify your email address.`,
    description: `Enter your email address and we'll send you a one-time code to verify.`,
    link1: {
      url: 'https://www.browserless.io/contact/',
      title: 'Contact us for licensing',
    },
  },
  sidebar: {
    items: [
      {
        title: 'Select Tier',
        isCompleted: true,
      },
      {
        title: 'Verify Email',
        isActive: true,
        isCompleted: false,
      },
      {
        title: 'Complete',
      },
    ],
  },
};

const SignUpPage = () => (
  <MainLayout headerTheme="grey-5">
    <VerifyEmail
      {...verifyEmailDetails}
      sidebar={{
        items: verifyEmailDetails.sidebar.items,
        button1: {
          url: '/sign-up',
          title: 'Back',
        },
        button2: {
          url: '/sign-up-complete',
          disabled: true,
          title: 'Next',
        },
      }}
    />
  </MainLayout>
);

export default SignUpPage;
