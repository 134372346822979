/* eslint-disable no-return-assign */
import { navigate } from 'gatsby';
import React, { useState } from 'react';

import Button from 'components/shared/button';
import Input from 'components/shared/input';
import SignUpLayout, {
  signUpLayoutPropTypes,
} from 'components/shared/sign-up-layout/sign-up-layout';
import * as sessionParams from 'constants/sessionStorage';
import { verifyEmail, markEmailVerified, setParam } from 'utils/api';
import { verificationMailEvent } from 'utils/keys';
import { validEmail } from 'utils/utils';

const VerifyEmail = (props) => {
  const [oneTimeCode, setOneTimeCode] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isVerifying, setVerifying] = useState(false);

  const onEmailChange = (evt) => {
    const email = evt.target.value;
    setParam(sessionParams.email, email);
    setEmail(email);
  };

  const onCodeChange = (evt) => {
    const code = evt.target.value;
    setParam(sessionParams.oneTimeCode, code);
    setOneTimeCode(code);
  };

  const onVerifyClick = async () => {
    if (validEmail(email)) {
      setError('');
    }

    return verifyEmail({ email })
      .then(() => {
        setVerifying(true);
      })
      .catch((e) => setError(e.message));
  };

  const onSubmitCode = async () => {
    setError('');

    return markEmailVerified({ email, secret: oneTimeCode })
      .then(() => {
        // eslint-disable-next-line no-undef
        gtag('event', verificationMailEvent, { data: email });
        navigate('/sign-up-complete');
      })
      .catch((e) => setError(e.message));
  };

  return (
    <SignUpLayout {...props}>
      <div className="items-center mt-4 space-x-5 text-2xl font-semibold xl:text-xl sm:flex-col sm:space-x-0 sm:items-stretch sm:space-y-2">
        <div className="items-center space-x-3 sm:relative">
          {isVerifying && (
            <div className="flex space-x-5 md:space-x-3 sm:flex-col sm:space-x-0 sm:space-y-3">
              <Input type="text" value={oneTimeCode} placeholder="000000" onChange={onCodeChange} />
              <Button
                size="md"
                theme="primary-black-filled"
                disabled={oneTimeCode.length < 6}
                onClick={onSubmitCode}
              >
                Submit Code
              </Button>
            </div>
          )}
          {!isVerifying && (
            <div className="flex space-x-5 md:space-x-3 sm:flex-col sm:space-x-0 sm:space-y-3">
              <Input
                type="email"
                value={email}
                placeholder="your@email.com"
                onChange={onEmailChange}
              />
              <Button
                size="md"
                theme="primary-black-filled"
                disabled={!validEmail(email)}
                onClick={onVerifyClick}
              >
                Verify
              </Button>
            </div>
          )}
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative !mx-0 my-2"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
        </div>
      </div>
    </SignUpLayout>
  );
};

VerifyEmail.propTypes = signUpLayoutPropTypes;

export default VerifyEmail;
